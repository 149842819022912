import api from "./api";
import ForgotPasswordRequest from "../../types/api/requests/user/ForgotPasswordRequest";
import ResetPasswordRequest from "../../types/api/requests/user/ResetPasswordRequest";
import UserResponse from "../../types/api/responses/user/UserResponse";
import {UserRequest} from "../../types/api/requests/user/UserRequest";
import {UsersResponse} from "../../types/api/responses/user/UsersResponse";
import DynamicListRequest from "../../types/api/requests/DynamicListRequest";
import UrlHelper from "../../helpers/UrlHelper";
import {ActionTokenRequest} from "../../types/api/requests/actiontoken/ActionTokenRequest";

const usersApi = api.injectEndpoints({
    endpoints: build => ({
        getMe: build.query<UserResponse, void>({
            query: () => '/api/users/me',
            providesTags: ['avatar'],
        }),

        actionTokenValidation: build.mutation<boolean, ActionTokenRequest>({
            query: (token) => ({
                url: `/api/public/users/action_token/validate`,
                method: 'POST',
                body: token,
            }),
            transformResponse: (query, meta: {response: {status: number}}): boolean => {
                if (!meta!.response) {
                    return false;
                }

                return 204 === meta!.response.status;
            }
        }),

        getUserCollection: build.query<UsersResponse, DynamicListRequest>({
            query: (request) => UrlHelper.buildUrl('/api/users', request),
        }),

        createUser: build.mutation<UserResponse, UserRequest>({
            query: (user) => ({
                url: '/api/users',
                method: 'POST',
                body: user,
            })
        }),

        updateUser: build.mutation<UserResponse, {id: number, values: UserRequest}>({
            query: (user) => ({
                url: `/api/users/${user.id}`,
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify({...user.values}),
            }),
        }),

        updateUserStatus: build.mutation<UserResponse, {id: number, values: UserRequest}>({
            query: (user) => ({
                url: `/api/users/${user.id}`,
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify({...user.values}),
            })
        }),

        forgotPassword: build.mutation<boolean, ForgotPasswordRequest>({
            query: (request) => ({
                url: '/api/public/users/reset-password-request',
                method: 'POST',
                body: request,
            }),
            transformResponse: (query, meta: {response: {status: number}}): boolean => {
                if (!meta!.response) {
                    return false;
                }

                return 204 === meta!.response.status;
            }
        }),

        resetPassword: build.mutation<boolean, ResetPasswordRequest>({
            query: (request) => ({
                url: '/api/public/users/reset-password',
                method: 'POST',
                body: request,
            }),
            transformResponse: (query, meta: {response: {status: number}}): boolean => {
                if (!meta!.response) {
                    return false;
                }

                return 204 === meta!.response.status;
            }
        }),

        deleteUser: build.mutation<boolean, number>({
            query: (id) => ({
                url: `/api/users/${id}`,
                method: 'DELETE',
            })
        }),
    }),
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useActionTokenValidationMutation,
    useGetUserCollectionQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useUpdateUserStatusMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useDeleteUserMutation,
} = usersApi;
export default usersApi;
