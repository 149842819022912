import api from "./api";
import FileResponse from "../../types/api/responses/fileupload/FileResponse";

const uploadApi = api.injectEndpoints({
    endpoints: build => ({
        uploadFile: build.mutation<FileResponse, FormData>({
            query: (file) => ({
                url: `/api/file_uploads`,
                method: 'POST',
                body: file,
            }),
        }),

    }),
});

export const {
    useUploadFileMutation,
} = uploadApi;

export default uploadApi;

