import React, {FC} from "react";
import {ButtonProps} from "@mui/material/Button/Button";
import {useUploadFileMutation} from "../../redux/api/upload";
import {ImageResponse} from "../../types/api/responses/image/ImageResponse";
import {UploadFileOutlined} from "@mui/icons-material";
import { DropzoneArea } from "mui-file-dropzone";

type ImageUploadFieldProps = ButtonProps & {
    label?: string,
    onUploaded?: (image: ImageResponse, file: File) => void,
}

export const UploadZone: FC<ImageUploadFieldProps> = ({label, onUploaded}) => {
    const [uploadFile, {isLoading: isUploading}] = useUploadFileMutation();

    const handleFilesChange = async (files: File[]) => {
        if (!files || files.length === 0) {
            return;
        }

        let formData = new FormData();
        formData.append('file', files[0]);

        console.log(formData);

        try {
            const imageData = await uploadFile(formData).unwrap();
            onUploaded && onUploaded(imageData, files[0]);
        } catch (error) {
            console.error("Error uploading file:", error);
        }
    }

    return (
        <>
            <DropzoneArea
                fileObjects={[]}
                filesLimit={1}
                Icon={UploadFileOutlined}
                acceptedFiles={['image/*']}
                dropzoneText={label}
                onChange={(files) => handleFilesChange(files)}
            />
        </>
    );
}
