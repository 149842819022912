import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";
import theme from "../../../../themes/default";
import {Button, CircularProgress, Divider, Grid, Paper, Stack, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useNavigate, useParams} from "react-router-dom";
import {useGetSourceQuery, useSaveSourceMutation} from "../../../../redux/api/source";
import LabelPreview from "../../../form/LabelPreview";
import {SourceData} from "../../../../types/api/responses/source/SourceResponse";
import {Field, Form, Formik, FormikHelpers, useFormik} from "formik";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import {TextField} from 'formik-mui';

const EditLabel = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const labelId = id || "0"
    const {data: labelData, isLoading: isLoading} = useGetSourceQuery({id: parseInt(labelId)});
    const [saveSource] = useSaveSourceMutation();
    const [sourceData, setSourceData] = useState<SourceData>();

    useEffect(() => {
        if (labelData) {
            try {
                setSourceData(labelData.sourceData);
            } catch (error) {
                console.error("Error parsing source data or nutrition facts: ", error);
            }
        }
    }, [labelData]);

    const submit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        try {
            const updatedSourceData: { info: { additional_information: any; dosage_and_use: any; contents: any; ingredients: any }
            } = {
                ...sourceData,
                info: {
                    ...sourceData?.info,
                    ingredients: values.info.ingredients,
                    dosage_and_use: values.info.dosage_and_use,
                    additional_information: values.info.additional_information,
                    contents: values.info.contents,
                }
            };

            const response = await saveSource({id: parseInt(labelId), sourceData: updatedSourceData});
            navigate('/application/labels');
        } catch (err) {
            ApiErrorHelper.processErrors(err, formikHelpers);
            formikHelpers.setSubmitting(false);
        }
    };

    const handleNutritionFactsChange = (updatedNutritionFacts: SourceData['nutrition_facts']) => {
        setSourceData(prevData => {
            if (!prevData) return undefined;
            return {
                ...prevData,
                nutrition_facts: updatedNutritionFacts,
            };
        });
    };

    if (isLoading) {
        return <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>;
    }

    const formInitialValues = {
        info: {
            ingredients: labelData?.sourceData.info.ingredients || '',
            dosage_and_use: labelData?.sourceData.info.dosage_and_use || '',
            additional_information: labelData?.sourceData.info.additional_information || '',
            contents: labelData?.sourceData.info.contents || '',
        }
    }

    return (
        <>
            <Helmet>
                <title>{t('helmet:labels')}</title>
            </Helmet>
            <Formik
                validateOnChange={false}
                initialValues={formInitialValues}
                onSubmit={submit}
            >
                {({ submitForm}) => (
                    <Form>
                        <Stack spacing={2}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    overflow: "hidden",
                                    boxShadow: "none",
                                    border: `1px solid ${theme.palette.primary.light}`
                                }}
                            >
                                <Grid container>
                                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                                        <Grid item sm={10}>
                                            <Typography variant="h5" py={0.75}>
                                                {t('pageTitle:editLabel')}
                                            </Typography>
                                        </Grid>
                                    </Stack>
                                    <Divider sx={{width: "100%"}}/>
                                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                                        <Grid item sm={3}>
                                            <Typography>{t('labels:ingredients')}</Typography>
                                        </Grid>
                                        <Grid item sm={9}>
                                            <Field
                                                multiline
                                                fullWidth
                                                component={TextField}
                                                label={''}
                                                name={"info.ingredients"}
                                                rows={2}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                                        <Grid item sm={3}>
                                            <Typography>{t('labels:dosage')}</Typography>
                                        </Grid>
                                        <Grid item sm={9}>
                                            <Field
                                                multiline
                                                fullWidth
                                                component={TextField}
                                                label={''}
                                                name={"info.dosage_and_use"}
                                                rows={2}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                                        <Grid item sm={3}>
                                            <Typography>{t('labels:info')}</Typography>
                                        </Grid>
                                        <Grid item sm={9}>
                                            <Field
                                                multiline
                                                fullWidth
                                                component={TextField}
                                                label={''}
                                                name={"info.additional_information"}
                                                rows={2}
                                            />
                                        </Grid>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} py={2} pr={2} pl={3} width={"100%"}>
                                        <Grid item sm={3}>
                                            <Typography>{t('labels:contents')}</Typography>
                                        </Grid>
                                        <Grid item sm={9}>
                                            <Field
                                                multiline
                                                fullWidth
                                                component={TextField}
                                                label={''}
                                                name={"info.contents"}
                                                rows={2}
                                            />
                                        </Grid>
                                    </Stack>
                                </Grid>

                            </Paper>
                            {sourceData?.nutrition_facts &&
                                <Paper sx={{width: "100%"}}>
                                    <LabelPreview data={sourceData.nutrition_facts}
                                                  onDataChange={handleNutritionFactsChange}/>
                                    <Box p={2} sx={{justifyContent: "right", display: "flex"}}>
                                        <Button
                                            sx={{
                                                background: "#01C677",
                                                borderRadius: 0,
                                                color: 'white',
                                                textTransform: 'none',
                                                padding: "8px 15px"
                                            }}
                                            onClick={submitForm}
                                        >
                                            {t('labels:generateLabel')}
                                        </Button>
                                    </Box>
                                </Paper>
                            }
                        </Stack>
                    </Form>
                )}
            </Formik>
        </>
);
};

export default EditLabel;
