import React, { FC, useState } from "react";
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Typography,
    Grid, Backdrop
} from "@mui/material";
import { useFormik } from "formik";
import FileResponse from "../../../../types/api/responses/fileupload/FileResponse";
import {AutocompleteMultipleWithNewField} from "../../../form/AutocompleteMultipleWithNewField";
import {
    useCreateSkuMutation,
    useGetSkusWithoutLabelsCollectionQuery
} from "../../../../redux/api/skus";
import { UploadZone } from "../../../form/UploadZone";
import { Box } from "@mui/system";
import { useCreateSourceMutation } from "../../../../redux/api/source";
import { useNavigate } from "react-router-dom";
import { SourceRequest } from "../../../../types/api/requests/source/SourceRequest";
import {useTranslation} from "react-i18next";

interface CreateLabelFormProps {
    title?: string
}

export const CreateLabelForm: FC<CreateLabelFormProps> = ({}) => {
    const {t} = useTranslation();
    const [createSku] = useCreateSkuMutation();
    const navigate = useNavigate();
    const [createSource] = useCreateSourceMutation();
    const { data: skus, isLoading: isLoading } = useGetSkusWithoutLabelsCollectionQuery({ page: 1, pageSize: 999999999 });
    const [isGenerating, setIsGenerating] = useState(false);

    const validate = (values: SourceRequest) => {
        const errors: { skusA5?: string; skusA6?: string; fileUpload?: string } = {};


        if (values.skusA5.length === 0 && values.skusA6.length === 0) {
            errors.skusA5 = t('errors:noSkusA5');
            errors.skusA6 = t('errors:noSkusA6');
        }

        if (values.fileUpload === 0) {
            errors.fileUpload = t('errors:noFileUploaded');
        }

        return errors;
    };

    const formik = useFormik<SourceRequest>({
        initialValues: {
            skusA5: [],
            skusA6: [],
            fileUpload: 0
        },
        validate,
        onSubmit: async (values, formikHelpers) => {
            try {
                setIsGenerating(true);
                const source = await createSource(formik.values).unwrap();
                navigate(`/application/labels/edit/${source.id}`)
            } catch (e) {
                formikHelpers.setSubmitting(false);
            }
            setIsGenerating(false);
        }
    });

    const onUploaded = async (file: FileResponse) => {
        await formik.setFieldValue('fileUpload', file.id);
    };

    if (isLoading || skus === undefined) {
        return <Box sx={{ display: "flex", justifyContent: "center" }}><CircularProgress /></Box>;
    }

    return (
        <>
            <Container sx={{minWidth: "700px"}}>
                <Divider />
                <Box sx={{ marginBottom: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography padding={"36px"} variant={"h5"}>A5: </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <AutocompleteMultipleWithNewField
                                name="skusA5"
                                formik={formik}
                                label={t('labels:addSkuPlaceholder')}
                                sx={{width: "500px", padding: "40px 0 0"}}
                                options={skus['hydra:member'].map(sku => ({value: sku.id, label: sku.sku}))}
                                onNewEntry={ async (newValue) => {
                                    const newSku = await createSku({sku: newValue}).unwrap();
                                    return {value: newSku.id, label: newSku.sku};
                                }}
                            />
                            {formik.errors.skusA5 && formik.touched.skusA5 ? (
                                <div style={{ color: 'red' }}>
                                    {Array.isArray(formik.errors.skusA5)
                                        ? formik.errors.skusA5.join(', ')
                                        : formik.errors.skusA5}
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Typography padding={"36px"} variant={"h5"}>A6: </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <AutocompleteMultipleWithNewField
                                name="skusA6"
                                formik={formik}
                                label={t('labels:addSkuPlaceholder')}
                                sx={{width: "500px", padding: "40px 0 0"}}
                                options={skus['hydra:member'].map(sku => ({value: sku.id, label: sku.sku}))}
                                onNewEntry={async (value) => {
                                    const newSku = await createSku({sku: value}).unwrap();

                                    return {value: newSku.id, label: newSku.sku};
                                }}
                            />
                            {formik.errors.skusA6 && formik.touched.skusA6 ? (
                                <div style={{ color: 'red' }}>
                                    {Array.isArray(formik.errors.skusA6)
                                        ? formik.errors.skusA6.join(', ')
                                        : formik.errors.skusA6}
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>
                </Box>
                <UploadZone onUploaded={onUploaded} label={t('labels:upload')} sx={{ background: "white" }} />
                {formik.errors.fileUpload && formik.touched.fileUpload ? (
                    <div style={{ color: 'red' }}>{formik.errors.fileUpload}</div>
                ) : null}
                <Box sx={{ display: "flex", justifyContent: "right", marginTop: "20px" }}>
                    <Button
                        sx={{
                            background: "#01C677",
                            borderRadius: 0,
                            color: 'white',
                            textTransform: 'none',
                            padding: "8px 15px"
                        }}
                        onClick={formik.submitForm}
                        variant="contained"
                        color="primary"
                        disabled={!formik.values.fileUpload}
                    >
                        {t('labels:generateLabel')}
                    </Button>
                </Box>
            </Container>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isGenerating}
            >
                <Typography sx={{position: "absolute", top: "53%"}}>{t('labels:generatingLabel')}</Typography>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
