import React, {FC, useRef} from 'react';
import {Helmet} from "react-helmet-async";
import ResponsiveDataRow from "../../../ui/ResponsiveDataRow";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetSalesOrderQuery, useLazyGetLabelsToPrintQuery,
} from "../../../../redux/api/salesorders";
import {Box, Button, Card, CardContent, CardHeader, Container, Divider, Stack, Typography} from "@mui/material";
import {DateTimeRenderer} from "../../../datagrid/renderers/DateTimeRenderer";
import theme from "../../../../themes/default";
import DynamicDataGrid, {DynamicDataGridHandle} from "../../../datagrid/DynamicDataGrid";
import {GridColDef} from "@mui/x-data-grid";
import {useGetSalesOrderLineByOrderIdCollectionQuery} from "../../../../redux/api/salesorderlines";
import {ToggleRenderer} from "../../../datagrid/renderers/ToggleRenderer";
import {DataGridNoRowsOverlay} from "../../../datagrid/DataGridNoRowsOverlay";
import useSecurity from "../../../../hooks/useSecurity";
import ApiErrorHelper from "../../../../helpers/ApiErrorHelper";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper-https";
import NotificationHelper from "../../../../helpers/NotificationHelper";

const SalesOrderDetails: FC = () => {
    const {t} = useTranslation();
    const { id } = useParams();
    const orderId = id as unknown as number;
    const gridRef = useRef<DynamicDataGridHandle|null>(null);
    const {data} = useGetSalesOrderQuery({id: orderId});
    const [getLabelData] = useLazyGetLabelsToPrintQuery();

    const printLabels = async (salesOrderId: number) => {
        // Create a new instance of the object
        const browserPrint = new ZebraBrowserPrintWrapper();

        try {
            const pdf: Blob = await getLabelData({id: salesOrderId}).unwrap();

            const formData = new FormData();
            formData.append('json', JSON.stringify({
                device: await browserPrint.getDefaultPrinter(),
            }));
            formData.append('blob', pdf, 'label.pdf');


            await fetch('http://127.0.0.1:9100/write', {
                method: 'POST',
                body: formData
            });

            NotificationHelper.showSuccess(t('notification:sentLabelsToPrinter'));
        } catch (error) {
            ApiErrorHelper.processErrors({
                    status: 400,
                    data: {
                        message: t('notification:notAbleToPrint')
                    }
            });
        }
    }

    const salesOrderData = [
        { label: t('form:orderId'), value: data?.orderId, parseHtml: false },
        { label: t('form:createdAt'), value: data?.createdAt ? <DateTimeRenderer date={data.createdAt} /> : null, parseHtml: false },
        { label: t('form:status'), value: t(`datagrid:salesOrder:${data?.status}`), parseHtml: false },
        { label: t('form:countryCode'), value: data?.countryCode, parseHtml: false },
        { label: t('form:description'), value: data && data.description ? data.description : t('datagrid:salesOrder:noDescription'), parseHtml: false },
        { label: t('form:notes'), value: data && data.notes ? data.notes : t('datagrid:salesOrder:noNotes'), parseHtml: true },
    ];

    const salesOrderLineColumns: GridColDef[] = [
        { field: 'sku.sku', headerName: t('datagrid:salesOrderLine:sku'), editable: false, minWidth: 125, renderCell: (params) => params.row.sku.sku },
        { field: 'sku.description', headerName: t('datagrid:salesOrderLine:skuDescription'), editable: false, flex: 1, minWidth: 400, renderCell: (params) => params.row.sku.description },
        { field: 'quantity', headerName: t('datagrid:salesOrderLine:quantity'), editable: false, minWidth: 150, renderCell: (params) => params.row.quantity },
        { field: 'printLabels', headerName: t('datagrid:salesOrderLine:printLabels'), editable: false, minWidth: 100, renderCell: (params) =>
                <ToggleRenderer
                    value={params.row.printLabels}
                    color={params.row.printLabels ? 'success' : 'error'}
                />
        },
        { field: 'apply', headerName: t('datagrid:salesOrderLine:apply'), editable: false, minWidth: 100, renderCell: (params) =>
                <ToggleRenderer
                    value={params.row.apply}
                    color={params.row.apply ? 'success' : 'error'}
                />
        },
        ];

    return (
        <>
            <Helmet>
                <title>{`${t('helmet:salesOrderDetails')} - #${ data && data.orderId }`}</title>
            </Helmet>
            <Container sx={{width:"100%"}} maxWidth="xl" disableGutters>
                <Stack direction={"column"} spacing={3} sx={{ boxShadow: "none" }}>
                    <Card sx={{ boxShadow: "none", border: `1px solid ${theme.palette.primary.light}` }}>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={3} sx={{ boxShadow: "none" }}>
                            <CardHeader
                                title={ `${t('cardTitle:salesOrder')}: #${data ? data.orderId : null}` }
                                sx={{
                                    py: 3,
                                    px: 4,
                                    color: theme.palette.text.primary
                                }}
                            />
                            <Button
                                variant="contained"
                                size={'large'}
                                sx={{
                                    background: "#01C677",
                                    height: 48,
                                    width: {
                                        xs: 48,
                                        md: "auto"
                                    },
                                    p: {
                                        xs: 0,
                                        md: "15px",
                                    },
                                    mr: '32px !important',
                                    minWidth:48
                                }}
                                onClick={ () => printLabels(orderId) }
                            >
                                <Typography
                                    component="span"
                                    sx={{
                                        display: {
                                            xs: "none",
                                            md: "inline"
                                        }
                                    }}
                                    fontWeight={700}
                                    fontSize={13}
                                >
                                    {t('labels:printLabels')}
                                </Typography>
                            </Button>
                        </Stack>
                        <Divider/>
                        <CardContent>
                            <Box px={2}>
                                {salesOrderData.map((data) => (
                                    data.value && (
                                        <ResponsiveDataRow
                                            key={data.label}
                                            label={data.label}
                                            value={data.value}
                                            labelMinWidth={160}
                                            parseHtml={data.parseHtml}
                                        />
                                    )
                                ))}
                            </Box>
                        </CardContent>
                    </Card>

                    <DynamicDataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'sku.printLabels', sort: 'asc' }],
                            },
                        }}
                        slots={{
                            noRowsOverlay: DataGridNoRowsOverlay,
                            toolbar: () => null,
                        }}
                        ref={gridRef}
                        columns={salesOrderLineColumns}
                        queryHook={useGetSalesOrderLineByOrderIdCollectionQuery}
                        extraRequestParams={{id: orderId}}
                        autoHeight={true}
                        checkboxSelection={false}
                        disableRowSelectionOnClick={true}
                        sx={{
                            '& .MuiDataGrid-row': {
                                cursor: 'pointer',
                            },
                            boxShadow: "none",
                            border: `1px solid ${theme.palette.primary.light}`
                        }}
                    />
                </Stack>
            </Container>
        </>
    );
};

export default SalesOrderDetails;